import type { FC } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from '@components/auth/store/auth.store'
import { Button } from '@components/ui/buttons/Button2'
import { NoSSR } from '@components/ui/utils/NoSSR'
import { useUI } from '@contexts/ui/ui.context'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'

export const SignUpButton: FC = () => {
  const { Modal } = useUI()
  const { t } = useTypedTranslation('common')
  const router = useRouter()
  const routes = useStateAwareRoutes()
  const { isAuthenticated } = useAuth()

  async function handleLogin() {
    if (!isAuthenticated()) {
      Modal.openLogin()
      return
    }

    await router.push(routes.static.SEARCH)
  }

  const actionText = isAuthenticated()
    ? t('find-your-match')
    : t('landing-action')

  return (
    <NoSSR>
      <Button onClick={handleLogin} color={'primary'}>
        {actionText}
      </Button>
    </NoSSR>
  )
}
