import type { FC, ReactNode } from 'react'
import { useRef } from 'react'
import { useRouter } from 'next/router'
import { Autoplay, Navigation, Pagination, Scrollbar } from 'swiper/modules'
import { Swiper } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'
import 'swiper/css/scrollbar'
import { cn } from '@goatlab/react-ui'
import { getDirection } from '@src/utils/get-direction'
import { ArrowLeft, ArrowRight } from 'lucide-react'

interface CarouselPropsType {
  className?: string
  buttonGroupClassName?: string
  prevActivateId?: string
  nextActivateId?: string
  paginationFractionId?: string
  prevButtonClasses?: string
  nextButtonClasses?: string
  buttonSize?: 'default' | 'small'
  paginationVariant?: 'default' | 'circle'
  paginationPosition?: 'center' | 'left' | 'right'
  loop?: boolean
  centeredSlides?: boolean
  breakpoints?: any
  pagination?: any
  navigation?: any
  scrollbar?: any
  autoplay?: any
  type?: 'rounded' | 'circle' | 'list'
  isFraction?: boolean
  children: ReactNode
}

const Carousel: FC<CarouselPropsType> = ({
  children,
  className = '',
  buttonGroupClassName = '',
  prevActivateId = '',
  nextActivateId = '',
  paginationFractionId = '',
  prevButtonClasses = 'start-0',
  nextButtonClasses = 'end-0',
  buttonSize = 'default',
  paginationVariant = 'default',
  paginationPosition,
  breakpoints,
  loop = true,
  navigation = true,
  pagination = false,
  autoplay = false,
  type = 'circle',
  isFraction = false,
  ...props
}) => {
  const { locale } = useRouter()
  const dir = getDirection(locale)
  const prevRef = useRef<HTMLButtonElement>(null)
  const nextRef = useRef<HTMLButtonElement>(null)
  const classPagination = paginationPosition
    ? `pagination-${paginationPosition}`
    : ''

  const nextButtonClassName = cn(
    'duration-250 absolute flex h-7 w-7 translate-x-1/2 transform items-center justify-center rounded bg-white text-sm text-black shadow-navigation transition hover:bg-gray-900 hover:text-white focus:outline-none md:text-base lg:h-8 lg:w-8 lg:text-lg',
    {
      'rounded-full': type === 'circle',
      'text-sm md:text-base lg:h-9 lg:w-9 lg:text-xl xl:h-10 xl:w-10 3xl:h-12 3xl:w-12 3xl:text-2xl':
        buttonSize === 'default',
      '-translate-x-1/2 shadow-navigationReverse': dir === 'rtl',
      '!static': type === 'list',
    },
    nextButtonClasses,
  )

  const prevButtonClassName = cn(
    'duration-250 absolute flex h-7 w-7 -translate-x-1/2 transform items-center justify-center rounded bg-white text-sm text-black shadow-navigation transition hover:bg-gray-900 hover:text-white focus:outline-none md:text-base lg:h-8 lg:w-8 lg:text-lg',
    {
      'rounded-full': type === 'circle',
      'text-sm md:text-base lg:h-9 lg:w-9 lg:text-xl xl:h-10 xl:w-10 3xl:h-12 3xl:w-12 3xl:text-2xl':
        buttonSize === 'default',
      'translate-x-1/2 shadow-navigationReverse': dir === 'rtl',
      '!static': type === 'list',
    },
    prevButtonClasses,
  )
  return (
    <div
      className={`carouselWrapper relative ${className} ${classPagination} ${
        paginationVariant === 'circle' ? 'dotsCircle' : ''
      } ${type === 'list' ? '!static' : ''}`}
    >
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar]}
        loop={loop}
        autoplay={autoplay}
        breakpoints={breakpoints}
        pagination={pagination}
        dir={dir}
        navigation={
          navigation && prevRef.current
            ? {
                prevEl: prevActivateId.length
                  ? `#${prevActivateId}`
                  : prevRef.current,
                nextEl: nextActivateId.length
                  ? `#${nextActivateId}`
                  : nextRef.current,
              }
            : {}
        }
        {...props}
      >
        {children}
      </Swiper>
      {(Boolean(navigation) || Boolean(isFraction)) && (
        <div
          className={cn(
            `absolute top-3/4 z-10 ml-2 flex w-[95%] items-center md:ml-4 xl:ml-6 ${buttonGroupClassName}`,
            {
              '': type === 'list',
            },
          )}
        >
          {prevActivateId.length > 0 ? (
            <button
              className={prevButtonClassName}
              id={prevActivateId}
              aria-label="prev-button"
            >
              {dir === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
            </button>
          ) : (
            <button
              ref={prevRef}
              className={prevButtonClassName}
              aria-label="prev-button"
            >
              {dir === 'rtl' ? <ArrowRight /> : <ArrowLeft />}
            </button>
          )}

          {Boolean(isFraction) && (
            <div
              className="!w-[auto] text-center text-xs sm:text-base"
              id={paginationFractionId}
            />
          )}

          {nextActivateId.length > 0 ? (
            <button
              className={nextButtonClassName}
              id={nextActivateId}
              aria-label="next-button"
            >
              {dir === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
            </button>
          ) : (
            <button
              ref={nextRef}
              className={nextButtonClassName}
              aria-label="next-button"
            >
              {dir === 'rtl' ? <ArrowLeft /> : <ArrowRight />}
            </button>
          )}
        </div>
      )}
    </div>
  )
}

export default Carousel
