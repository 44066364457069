import type { LinkProps } from 'next/link'
import type { FC } from 'react'
import Image from 'next/image'
import Link from '@components/ui/Link'
import { useTypedTranslation } from '@src/utils/useTypedTranslation'
import cn from 'clsx'

interface IconCardProps {
  item: any
  effectActive?: boolean
  variant?: 'default' | 'modern' | 'circle' | 'list'
  href: LinkProps['href']
}

const CategoryCard: FC<IconCardProps> = ({
  item,
  effectActive = false,
  variant = 'default',
  href,
}) => {
  const { t } = useTypedTranslation('common')
  const { name, image } = item

  const icon = image?.src
    ? image.src.replace(/(https?:\/\/)|\/\//g, (m: string, group1: string) => {
        return group1 ? m : '/'
      })
    : ''

  return (
    <Link
      href={href}
      className="flex justify-center rounded-lg bg-gray-100 px-4 py-2"
    >
      <div
        className="flex w-[50%] flex-shrink-0 items-center pr-4"
        style={{ minHeight: '97px' }}
      >
        <Image
          src={`${icon}`}
          alt={name || t('text-card-thumbnail')}
          className="w-[300px] rounded-lg"
          width={150}
          height={150}
        />
        {effectActive && variant === 'circle' && (
          <>
            <div className="absolute left-0 top-0 h-full w-full rounded-full bg-black opacity-0 transition-opacity duration-300 group-hover:opacity-30" />
            <div className="absolute left-0 top-0 flex h-full w-full items-center justify-center rounded-full"></div>
          </>
        )}
      </div>

      <div
        className={cn('flex flex-col', {
          'overflow-hidden': variant === 'list',
        })}
      >
        <div className="pt-2 text-sm font-semibold capitalize">{name}</div>
      </div>
    </Link>
  )
}

export default CategoryCard
