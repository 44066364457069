import type { Unpacked } from '@goatlab/js-utils'
import type { RouterOutputs } from '@src/api/trpc'
import type { FC } from 'react'
import { useEffect, useRef, useState } from 'react'
import CategoryCard from '@components/common/categoryCard'
import SectionHeader from '@components/common/section-header'
import Carousel from '@components/ui/carousel/carousel'
import Alert from '@components/ui/notifications/Alert'
import { Ids } from '@goatlab/js-utils'
import { useLocalization } from '@goatlab/marketplace-i18n'
import { backendHook } from '@src/api/trpc'
import { useStateAwareRoutes } from '@src/hooks/useStateAwareRoutes'
import cn from 'clsx'
import { SwiperSlide } from 'swiper/react'

interface CategoriesProps {
  sectionHeading: string
  className?: string
  variant?: 'default' | 'modern' | 'circle' | 'list'
}

const breakpoints = {
  '1780': {
    slidesPerView: 6,
    spaceBetween: 12,
  },
  '1280': {
    slidesPerView: 6,
    spaceBetween: 12,
  },
  '1025': {
    slidesPerView: 5,
    spaceBetween: 12,
  },
  '768': {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  '480': {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  '0': {
    slidesPerView: 2,
    spaceBetween: 12,
  },
}
const breakpointsCircle = {
  '1720': {
    slidesPerView: 8,
    spaceBetween: 48,
  },
  '1400': {
    slidesPerView: 7,
    spaceBetween: 32,
  },
  '1025': {
    slidesPerView: 6,
    spaceBetween: 28,
  },
  '768': {
    slidesPerView: 5,
    spaceBetween: 20,
  },
  '500': {
    slidesPerView: 4,
    spaceBetween: 20,
  },
  '0': {
    slidesPerView: 3,
    spaceBetween: 12,
  },
}

const breakpointsList = {
  '1780': {
    slidesPerView: 5,
    spaceBetween: 12,
  },
  '1280': {
    slidesPerView: 4,
    spaceBetween: 12,
  },
  '1025': {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  '768': {
    slidesPerView: 3,
    spaceBetween: 12,
  },
  '480': {
    slidesPerView: 2,
    spaceBetween: 12,
  },
  '0': {
    slidesPerView: 1.3,
    spaceBetween: 12,
  },
}

type GroupedCategory = Unpacked<
  RouterOutputs['commerce']['categories']['grouped']
>

export const CategoryCarousel: FC<CategoriesProps> = ({
  className = 'mb-12 md:mb-14 xl:mb-16 pt-6',
  sectionHeading,
  variant = 'default',
}) => {
  const [categories, setCategories] = useState<GroupedCategory[]>()
  const stateRouter = useStateAwareRoutes()
  const currentLanguage = useLocalization().currentLanguageBackendFormat()

  const { data, error } = backendHook.commerce.categories.grouped.useQuery(
    {
      lang: currentLanguage,
    },
    {
      refetchOnWindowFocus: false,
    },
  )

  const isMounted = useRef(false)

  useEffect(() => {
    // Avoid modifying the categories if it is loading
    isMounted.current = true

    if (!isMounted.current) {
      return
    }

    const services = data?.find(
      (d) => d.name === 'Services' || d.name === 'Servicios',
    )

    setCategories(
      services
        ? services.children?.sort((a, z) => a.name.localeCompare(z.name))
        : [],
    )

    return () => {
      isMounted.current = false
    }
  }, [data])

  return (
    <div className={cn(className)}>
      <SectionHeader sectionHeading={sectionHeading} className="p-12" />
      {error ? (
        <Alert message={error?.message} />
      ) : (
        <Carousel
          autoplay={{
            delay: 3000,
          }}
          breakpoints={
            variant === 'circle'
              ? breakpointsCircle
              : variant === 'list'
                ? breakpointsList
                : breakpoints
          }
          buttonGroupClassName={variant === 'circle' ? '-mt-4' : '-mt-2'}
        >
          {/* Duplicate slides to overcome swiper bug with swipe right */}
          {categories &&
            [...categories, ...categories].map((category) => (
              <SwiperSlide
                key={`category--icon-key-${category.id}_${Ids.nanoId(4)}`}
              >
                <CategoryCard
                  item={category}
                  href={`${stateRouter.static.SEARCH}?category=${category.slug}`}
                  effectActive={false}
                  variant={variant}
                />
              </SwiperSlide>
            ))}
        </Carousel>
      )}
    </div>
  )
}
