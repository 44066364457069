import type { FC } from 'react'

interface AlertProps {
  message?: string
}

const Alert: FC<AlertProps> = ({ message }) => {
  return (
    <div className="flex h-full items-center justify-center rounded border border-red-200 px-5 py-4 text-sm font-semibold text-red-600">
      {message}
    </div>
  )
}

export default Alert
